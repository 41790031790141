<template>
  <div class="homepage" :style="isLogger?'':'margin-top: 60px'">
    <div ref="hpage" class="tac">
      <div v-if="isLogger" ref="myNav" class="top_banner">
        <div class="top_banner_text">
          <a href="javascript:;" style="fontSize:16px;fontWeight:400;color:#000">个人主页</a>
        </div>
      </div>
      <div :class="vip_level === 2 ? 'top_box_e' : 'top_box'">
        <div class="top_content">
          <div class="top_content_top">
            <!-- 头像信息 -->
            <div class="u_head">
              <!--  -->
              <div v-if="is_avatar==0&&uid==toUid" v-show="loadingOk" class="need_ava" @click="toggleShow">
                点此更换头像，即得<b>500</b>{{ zlb_name }}。
              </div>
              <img v-if="vip_level === 2" class="u_crown" src="@/assets/crown.png" alt>
              <img v-else-if="vip_level === 4" class="u_crown" src="@/assets/crown_e.png" alt>
              <div
                :class="{'u_head_bg_v':vip_level===2,'u_head_bg_e':vip_level===4,'u_head_bg':(vip_level!==2&&vip_level!==4)}"
              >
                <div v-if="uid==toUid" class="head" @click="toggleShow">
                  <img :src="avatar" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
                  <div class="change_ava">修改头像</div>
                </div>
                <div v-else class="head">
                  <img :src="avatar" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
                </div>
                <my-upload
                  v-if="show_box"
                  ref="myUpload"
                  :model-value.sync="show_box"
                  field="img"
                  :no-square="true"
                  img-format="jpg"
                  :size="size"
                  url="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar"
                  @crop-upload-success="handleAvatarSuccess"
                ></my-upload>
                <!-- 上传头像 -->
                <div class="home-tab-body-img">
                </div>
                <!-- 剪切图片的弹框-->
                <div class="upload-dialog">
                  <a-modal
                    v-model="isCropper"
                    title="图片裁剪"
                    class="upload_dialog_a"
                    :on-ok="false"
                    footer
                  >
                    <div class="vue-cropper-box">
                      <div class="vue-cropper-content" style="height:400px;width:100%;">
                        <a-row>
                          <a-col :span="16">
                            <vueCropper
                              ref="cropper"
                              style="height:400px;"
                              :img="option.img"
                              :output-size="option.size"
                              :output-type="option.outputType"
                              :info="option.info"
                              :full="option.full"
                              :can-move="option.canMove"
                              :can-move-box="option.canMoveBox"
                              :center-box="option.centerBox"
                              :original="option.original"
                              :auto-crop="option.autoCrop"
                              :auto-crop-width="option.autoCropWidth"
                              :auto-crop-height="option.autoCropHeight"
                              :fixed-box="option.fixedBox"
                              :fixed="option.fixed"
                              :fixed-number="option.fixedNumber"
                              @realTime="realTime"
                              @imgLoad="imgLoad"
                              @cropMoving="cropMovingFun"
                            ></vueCropper>
                          </a-col>
                          <a-col :span="8" style="padding-left:30px;">
                            <div class="preview-box">
                              <div>预览：</div>
                              <div
                                :style="previews.div"
                                class="preview"
                                style="overflow:hidden;border:1px solid #f1f1f1;"
                              >
                                <img :src="previews.url" :style="previews.img">
                              </div>
                            </div>
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                    <div class="operate-wrap">
                      <div class="up_foot_box clearfix">
                        <a-button
                          class="up_foot_button"
                          type="primary"
                          style="position:relative;display:inline-block;width: 70px;padding: 0;text-align: center;line-height: 28px;"
                        >
                          选择图片
                          <input
                            id="uploads"
                            type="file"
                            style="position:absolute;z-index:1001;left:0;top:0; width:70px;opacity:0;"
                            accept="image/png, image/jpeg, image/gif, image/jpg"
                            @change="beforeAvatarUploadPS"
                          >
                        </a-button>
                        <a-button class="up_foot_button" type="primary" plain @click="changeScale(1)">放大</a-button>
                        <a-button
                          class="up_foot_button"
                          type="primary"
                          plain
                          @click="changeScale(-1)"
                        >缩小</a-button>
                      </div>
                    </div>
                  </a-modal>
                </div>
              </div>
            </div>
            <!-- 用户信息 -->
            <div class="ts_li">
              <div class="u_d_white1">
                <p>
                  {{ is_lecturer == 1?nick_name:username }}
                  <span v-if="is_lecturer == 1" class="characteristic">专家</span>
                </p>
                <div v-if="vip_level === 2" class="ts_show">
                  <img class="u_crown vip_icon_hover" src="@/assets/vip_icon.png" alt>
                </div>
                <div v-else-if="vip_level === 4" class="ts_show">
                  <img class="u_crown evip_icon_hover" src="@/assets/evip_icon.png" alt>
                </div>
                <div v-if="is_lecturer != 1" class="user_tag">
                  {{ user_level }}
                </div>
              </div>
              <div v-if="city" class="u_d_gray u_address">
                <img :src="vip_level ==2?require('@/assets/vip_user_desc.png'):require('@/assets/user_desc.png')" alt="">
                {{ city }}
                <span v-if="(city && city.length > 0)&&(special && special.length > 0)" style="margin:0 4px;">|</span>
                {{ special }}
              </div>
              <div>
                <div v-if="is_lecturer == 1" class="u_d_gray zj_ts_bs">
                  {{ mark }}
                </div>
                <!-- <p v-if="is_lecturer == 1&&brief" v-show="!detail_show" class="detail" @click="showDetail">{{ detail_show?'收起':'详情...' }}</p> -->
                <!-- <div v-if="brief" v-show="detail_show" class="zj_ts_js">{{ brief }}</div> -->
                <!-- <p v-if="brief" v-show="detail_show" class="detail" @click="showDetail">{{ detail_show?'收起':'详情...' }}</p> -->
                <div v-if="brief" class="zj_ts_js">{{ brief }}</div>
              </div>
            </div>
          </div>
          <div class="top_content_bottom">
            <!-- 操作按钮 -->
            <div class="u_menu">
              <template v-if="!isLogger&&loadingOk">
                <span v-show="is_guanzhu === 1" class="u_sixin" @click="attentionPeaple">已关注</span>
                <span v-show="is_guanzhu === 0" class="u_guanzhu" @click="attentionPeaple">关注</span>
                <span class="u_sixin" @click="showMessageDialog">私信</span>
              </template>
              <span v-if="isLogger" class="u_sixin" @click="editInfo">编辑</span>
              <el-dialog
                class="post_msg"
                title="发送私信"
                :visible.sync="postMsg"
                top="300px"
                width="550px"
                :close-on-click-modal="false"
                @close="handleClose"
              >
                <el-form ref="msgRef" :model="inputMSg" :rules="msgRule" label-width="80px">
                  <!-- :autosize="{ minRows: 5, maxRows: 7}" -->
                  <el-form-item label="输入内容" prop="msg">
                    <el-input
                      v-model="inputMSg.msg"
                      type="textarea"
                      placeholder="请输入工作内容"
                      maxlength="200"
                      :autosize="{ minRows: 5, maxRows: 7}"
                      show-word-limit
                    ></el-input>
                  </el-form-item>
                </el-form>
                <!-- 弹窗尾部 -->
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="sendMessage('msgRef')">发 送</el-button>
                </div>
              </el-dialog>
            </div>
            <!-- 个人数据 -->
            <div class="u_detail">
              <ul>
                <!--  @click="goInterestList" -->
                <li style="cursor: pointer" @click="goInterestList">
                  <div class="detail_item_title">关注</div>
                  <div class="detail_item_num">{{ idolnum }}</div>
                  <div class="unit">位</div>
                </li>
                <!-- @click="goFanList" -->
                <li style="cursor: pointer" @click="goFanList">
                  <div class="detail_item_title">粉丝</div>
                  <div class="detail_item_num">{{ fansnum }}</div>
                  <div class="unit">位</div>
                </li>
                <li>
                  <div class="detail_item_title">发帖</div>
                  <div class="detail_item_num">{{ threadnum }}</div>
                  <div class="unit">篇</div>
                </li>
                <li>
                  <div class="detail_item_title">荣誉分</div>
                  <div class="detail_item_num">{{ rongyu_score }}</div>
                  <div class="unit">分</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <transition name="fade-transform" mode="out-in">
        <div class="bottom_box">
          <div v-if="$route.name!=='InterestList'&&$route.name!=='FanList'" class="bot_top">
            <p v-if="is_lecturer == 1" :class="item_index===3 ? 't_thread_s' : 't_thread'" @click="showLseeon">课程</p>
            <span v-if="is_lecturer == 1" class="bot_line"></span>
            <p v-if="threadnum!=0" :class="item_index===0 ? 't_thread_s' : 't_thread'" @click="showThread">
              发帖
              <span>({{ threadnum }})</span>
            </p>
            <span v-if="threadnum!=0" class="bot_line"></span>
            <p :class="item_index===1 ? 't_archive_s' : 't_archive'" @click="showArchive">档案</p>
            <div class="bot_right">
              <img style="margin:-2px 5px 0 0;" src="@/assets/image/archive_study_icon.png" alt>
              <span>本周学习时长：</span>
              <span class="bot_right_g">
                <span v-if="Math.floor(lessonTime/60)">{{ Math.floor(lessonTime/60) }}小时</span>
                <span v-if="lessonTime%60">{{ lessonTime%60 }}分钟</span>
                <span v-if="lessonTime===0">{{ lessonTime }}分钟</span>
              </span>
              <!-- <img style="margin:-4px 5px 0 30px;" src="@/assets/image/archive_level_icon.png" alt>
              <span>用户等级：</span>
              <span class="bot_right_g">{{ user_level }}</span> -->
            </div>
          </div>
          <router-view @briefShow="briefShow"></router-view>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { myArchiveInfo, follows, cancelfollow } from '@/api/center'
import { isFollowUser, sendMessage } from '@/api/archive'
import { updateIcon, getRow } from '@/api/mine'
import { VueCropper } from 'vue-cropper'
import myUpload from 'vue-image-crop-upload'
import Cookies from 'js-cookie'
export default {
  inject: ['reload'],
  components: {
    VueCropper,
    myUpload
  },
  data() {
    return {
      uid: Cookies.get('uid') || '',
      postMsg: false,
      inputMSg: {},
      msgRule: {
        msg: [
          { required: true, message: '请输入私信内容', trigger: 'blur' },
          { min: 2, max: 200, message: '长度在 2 到 200 个字符', trigger: 'blur' }
        ]
      },
      item_index: 3,
      toUid: this.$route.query.u ? this.$route.query.u : '',
      avatar: '',
      username: '',
      vip_level: 0,
      idolnum: 0,
      fansnum: 0,
      threadnum: 0,
      rongyu_score: 0,
      lessonTime: 0,
      user_level: '',
      city: '',
      special: '',
      is_guanzhu: 0,
      isLogger: false,
      // cutImg: require('./img/cut.jpg'),
      fileImgList: [],
      isCropper: false,
      // 裁剪组件的基础配置option
      previews: {},
      zlb_name: '',
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        full: true, // 输出原图比例截图 props名full
        outputType: 'jpg', // 裁剪生成额图片的格式
        canMove: false, // 能否拖动图片
        original: false, // 上传图片是否显示原始宽高
        canMoveBox: true, // 能否拖动截图框
        centerBox: true, // 截图框是否限制在图片里面
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 180, // 默认生成截图框宽度
        autoCropHeight: 180, // 默认生成截图框高度
        fixedBox: true, // 截图框固定大小
        fixed: true,
        fixedNumber: [1, 1] // 截图框的宽高比例
      },
      show_box: false, // 剪切框显示和隐藏的flag
      size: 2.1,
      is_avatar: 1,
      is_lecturer: 0, // 是否是专家
      brief: '', // 专家介绍
      mark: '',
      toFirst: true,
      avatar_url: '', // 专家头像
      detail_show: false, // 专家简介
      loadingOk: false,
      show_fans: 0, // 是否允许查看
      show_follows: 0// 是否允许查看
    }
  },
  computed: {
    top() {
      return this.$refs.myNav.offsetTop
    }
  },
  watch: {
    '$route.path'(newV) {
      this.cntrolMenuIndex()
    },
    '$route.query'(newV) {
      this.toUid = newV.u
      this.loadingOk = false
      this.getUerArchiveInfo()
      this.getGuanzhuStatu()
      this.getScoreName()
    }
  },
  mounted() {
    this.nav = this.$refs.myNav
    window.addEventListener('scroll', this.fixedNav)
  },
  beforeCreate() {
    if (this.toUid === '' || this.toUid === undefined) {
      // 跳走
    }
  },
  created() {
    this.getUerArchiveInfo()
    this.getGuanzhuStatu()
    this.getScoreName()
  },
  methods: {
    goInterestList() {
      // if(this.uid==toUid)
      if (this.show_follows == 1 && this.loadingOk) {
        this.$router.push({
          path: '/archive/interestList',
          query: {
            u: this.toUid,
            isSelf: this.isLogger
          }
        })
      } else {
        this.$message.error('该用户未允许，关注不可见')
      }
    },
    goFanList() {
      if (this.show_fans == 1 && this.loadingOk) {
        this.$router.push({
          path: '/archive/FanList',
          query: {
            u: this.toUid,
            isSelf: this.isLogger
          }
        })
      } else {
        this.$message.error('该用户未允许，粉丝不可见')
      }
    },
    showDetail() {
      this.detail_show = !this.detail_show
    },
    editInfo() {
      window.open('https://www.zhulong.com/ucenter/registinfo')
    },
    // 上传组件头像
    toggleShow() {
      this.show_box = !this.show_box
      // this.$refs.myUpload.setStep(1)
    },
    // 修改头像
    handleAvatarSuccess(res, file) {
      this.show_box = false
      const param = { filename: res.url }
      updateIcon(param).then((res) => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.$message.success('头像修改成功')
          location.reload()
          // const avaurl = res.result.result
          // this.avatar = avaurl
          // this.getUserInfo()
          // // this.$message.success('头像修改成功')
          // location.reload()
        } else {
          this.$message.error('头像修改失败')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getScoreName() {
      const p = {
        type: '1'
      }
      getRow(p).then(res => {
        if (res.result && res.result.name) {
          const origin = window.location.origin
          // if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
          //   this.zlb_name = '注考币'
          // } else {
            this.zlb_name = res.result.name
          // }
        }
      })
    },
    cropMovingFun(q, w, e) {
      console.log(q, w, e)
    },
    // 实时预览函数
    realTime(data) {
      console.log('realTime:', data)
    },
    handleClose() {
      this.$refs.msgRef.resetFields()
    },
    showMessageDialog() {
      this.postMsg = true
      this.inputMSg = { msg: '' }
    },
    // / 发送私信
    sendMessage(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          // 验证通过 调接口
          const input = this.inputMSg.msg
          this.sendMessageRequest(input)
        } else {
          console.log('error')
          return false
        }
      })
    },
    // 请求发送
    sendMessageRequest(msg) {
      // console.log(msg)
      const info = {
        not_systerm: 1,
        touid: this.toUid,
        message: msg
      }
      sendMessage(info).then(res => {
        if (res.errNo === 0) {
          this.postMsg = false
          this.$message.success('发送成功')
        }
      })
    },
    cntrolMenuIndex() {
      const router_path = this.$route.path
      if (router_path === '/archive/archiveInfo') {
        this.item_index = 1
      } else if (router_path === '/archive/archiveLesson') {
        this.item_index = 3
      } else {
        console.log(this.threadnum, 'this.threadnumthis.threadnum')
        if (this.threadnum != 0) {
          this.item_index = 0
        } else {
          this.showArchive()
        }
      }
    },
    showThread() {
      this.item_index = 0
      this.$router.push({ path: '/archive', query: { u: this.toUid }})
    },
    showArchive() {
      this.item_index = 1
      this.$router.push({ path: '/archive/archiveInfo', query: { u: this.toUid }})
    },
    showLseeon() {
      this.item_index = 3
      this.$router.push({ path: '/archive/archiveLesson', query: { u: this.toUid }})
    },
    // 用户档案信息
    getUerArchiveInfo() {
      this.loadingOk = false
      const param = { uid: this.toUid }
      myArchiveInfo(param).then(res => {
        this.loadingOk = true
        const result = res.result || {}
        this.avatar = result.avatar + '?t=' + new Date().getTime() 		// 用户头像地址
        this.vip_level = result.vip_level
        sessionStorage.setItem('friend_vip', this.vip_level)
        this.idolnum = result.idolnum
        this.show_follows = result.show_follows
        this.show_fans = result.show_fans
        this.fansnum = result.fansnum
        this.threadnum = result.threadnum
        this.rongyu_score = result.experience
        this.lessonTime = result.lessonTime
        this.user_level = result.user_level_name
        this.username = result.username
        this.city = result.city || ''
        this.special = result.specialty || ''
        // 1本人，0非本 人
        this.isLogger = result.is_self
        this.is_avatar = result.is_avatar
        // 是否是专家 1，是  2不是
        this.is_lecturer = result.is_lecturer
        // this.is_lecturer = 1
        this.brief = result.intro
        this.mark = result.user_tags.join('、')
        // this.nick_name = result.realname
        // this.avatar_url = result.avatar

        // if (this.nick_name && this.nick_name.length > 0) {
        // // 设置title标题
        //   const my_title = document.getElementsByTagName('title')[0]
        //   my_title.innerHTML = this.nick_name
        // }
        if (this.mark && this.mark.length > 0) {
        // 设置关键词
          const my_keys = document.getElementsByName('keywords')[0]
          my_keys.content = this.mark
        }
        if (this.brief && this.brief.length > 0) {
        // 设置描述
          const my_description = document.getElementsByName('description')[0]
          my_description.content = this.brief
        }
        if (this.toFirst) {
          if (this.is_lecturer == 1) {
            this.$router.replace({ path: '/archive/archiveLesson', query: { u: this.toUid }})
          }
          this.cntrolMenuIndex()
          this.toFirst = false
        }
      }).catch(err => {
        this.loadingOk = true
        console.log(err)
      })
    },
    briefShow(brief, mark, nick_name, avatar_url) {
      console.log(nick_name, '00000000000')
      // this.brief = brief
      // this.mark = mark.join('、')
      this.nick_name = nick_name
      this.avatar_url = avatar_url

      if (nick_name && nick_name.length > 0) {
        // 设置title标题
        const my_title = document.getElementsByTagName('title')[0]
        my_title.innerHTML = nick_name
      }
      if (mark && mark.length > 0) {
        // 设置关键词
        const my_keys = document.getElementsByName('keywords')[0]
        my_keys.content = this.mark
      }
      if (brief && brief.length > 0) {
        // 设置描述
        const my_description = document.getElementsByName('description')[0]
        my_description.content = brief
      }
    },
    // / 获取关注状态
    getGuanzhuStatu() {
      const params = { uid: this.toUid }
      isFollowUser(params).then(res => {
        console.log(res)
        const errNo = res.errNo
        if (errNo === 0) {
          const r = res.result
          this.is_guanzhu = r
        }
      }).catch(err => {
        console.log(err)
      })
    },
    fixedNav() {
      this.$nextTick(() => {
        const top = document.documentElement.scrollTop
        if (top >= this.top) {
          this.$refs.myNav.style.position = 'fixed'
          this.$refs.myNav.style.top = '40px'
        }
        if (top < this.top) {
          this.$refs.myNav.style.position = 'relative'
          this.$refs.myNav.style.top = '0px'
        }
      })
    },
    attentionPeaple() {
      if (this.is_guanzhu === 0) {
        this.followsUser(this.toUid)
      } else {
        this.cancelFollowUser(this.toUid)
      }
    },
    cancelFollowUser(to_uid) {
      // 取消关注用户
      const param = { uid: to_uid }
      cancelfollow(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.is_guanzhu = 0
        } else {
          const msg = res.msg
          this.$message.error(msg)
          // Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    followsUser(to_uid) {
      // 关注用户
      const param = { uid: to_uid }
      follows(param).then(res => {
        const errNo = res.errNo
        if (errNo === 0) {
          this.is_guanzhu = 1
        } else {
          const msg = res.msg
          this.$message.error(msg)
          // Message({ message: msg, type: 'error' })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    handlePictureCardPreview(cutImg) {
      this.option.img = cutImg
      console.log(this.option.img)
      this.isCropper = true
    },
    beforeAvatarUploadPS(e) {
      var file = e.target.files[0]
      this.option.img = URL.createObjectURL(file)

      const isDWG = file.name.split('.')
      const format = isDWG[isDWG.length - 1]
      // this.uploadParams.isFile = "1";
      // uploadParams.file="";
      if (format != 'png' && format != 'jpg') {
        this.$message.error('上传文件只能是 png或jpg 格式!')
        return false
      }
      this.isCropper = true
    },
    // 然后我加了几个剪切的按钮进行旋转或放大，并把上传方法一并粘贴到如下：

    turnLeft() {
      this.$refs.cropper.rotateLeft()
    },
    turnRight() {
      this.$refs.cropper.rotateRight()
    },
    cancleCropper() {
      // 取消截图
      this.isCropper = false
    },
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    imgLoad(msg) {
      console.log('imgLoad')
      console.log(msg)
    },

    onCubeImg() {
      // 剪切上传
      // 获取cropper的截图的base64 数据
      this.$refs.cropper.getCropData(data => {
        // this.fileinfo.url = data;

        // 先将显示图片地址清空，防止重复显示
        this.option.img = ''
        this.cutImg = data
        // 将剪裁后base64的图片转化为file格式
        // let file = this.convertBase64UrlToBlob(data);
        // this.fileImg=URL.createObjectURL(file);
        console.log(data)
        this.isCropper = false
        // this.fileList=[{name: 'food.jpg', url: 'https://xxx.cdn.com/xxx.jpg'}]
      })
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(urlData) {
      const bytes = window.atob(urlData.split(',')[1]) // 去掉url的头，并转换为byte
      // 处理异常,将ascii码小于0的转换为大于0
      const ab = new ArrayBuffer(bytes.length)
      const ia = new Uint8Array(ab)
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i)
      }
      return new Blob([ab], { type: 'image/jpeg' })
    }

  }
}
</script>

<style lang="scss" scoped>
$top-left-box-width: 180px;
$top-left-box-margin: 50px;
.homepage {
  width: 100%;
  min-width: 1200px;
  margin: 110px auto 0;
  font-size: 14px;
  color: #444;
  font-family: "微软雅黑", Arial, Helvetica, sans-serif;
  text-align: center;
}
.homepage .tac {
  min-width: 1200px;
  margin: 0 auto;
  text-align: center;
  align-items: center;
}
.top_banner {
  position: fixed !important;
  top: 60px !important;
  width: 100%;
  min-width: 1200px;
  height: 50px;
  background: #fff;
  box-shadow: 0 2px 7px 5px rgba(0, 0, 0, 0.04);
  opacity: 1;
  text-align: left;
  position: relative;
  z-index: 1;
  span {
    color: #ddd;
    font-size: 18px;
    line-height: 48px;
  }
}
.top_banner_text {
  position: absolute;
  top: 0;
  left: 50%;
  min-width: 1200px;
  line-height: 50px;
  margin-left: -600px;
}
.top_box {
  width: 100%;
  min-height: 340px;
  background-color: #313131;
  padding-bottom: 40px;
}
.top_box_e {
  width: 100%;
  min-height: 340px;
  background-color: #7e6b5a;
  padding-bottom: 40px;
}
.top_box_v {
  width: 100%;
  min-height: 340px;
  background-color: #7e6b5a;
  padding-bottom: 40px;
}
.u_head {
  // margin: 0 auto;
  // padding-top: 50px;
  width: $top-left-box-width;
  // height: 170px;
  position: relative;
  margin-right: $top-left-box-margin;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  .need_ava{
    position:absolute;
    // z-index: 1;
    bottom: -34px;
    left: 20px;
    color: rgba(126, 108, 90, 1);
    width: 200px;
    // height: 32px;
    line-height: 22px;
    box-sizing: border-box;
    padding: 2px 8px;
    border-radius: 8px;
    background-color: rgba(235, 193, 139, 1);
    color: rgba(89, 89, 90, 1);
    font-size: 12px;
    text-align: left;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: -4px;
      left: 24px;
      border-width: 8px 8px 8px 0;
      border-style: solid;
      border-color: transparent transparent #EBC18B  transparent;
      transform: rotate(135deg);
    }
  }
  .u_head_bg {
    width: 128px;
    height: 128px;
    border: 4px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .u_head_bg_v {
    width: 128px;
    height: 128px;
    border: 4px solid #ebc28b;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .u_head_bg_e {
    width: 128px;
    height: 128px;
    border: 4px solid #cddbd7;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .u_crown {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -8px;
    left: 1px;
  }
}
.u_detail {
  // width: 1000px;
  flex: 1;
  height: 80px;
  margin: 0 auto;
  // margin-top: 5px;
  text-align: left;
  ul{
    display: flex;
    align-items: center;
    height: 100%;
  }
  li {
    height: 80px;
    // width: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color:#eeeeee;
    margin-right: 120px;
    position: relative;
  }
  li:nth-last-child(1){
    margin-right: 0;
  }
  .unit{
    position: absolute;
    font-size: 14px;
    bottom: 10px;
    right: -16px;
  }
  .detail_item_title{
    font-size: 14px;
  }
  .detail_item_num{
    font-size: 40px;
    font-weight: 800;
  }
}
// .u_detail li:nth-of-type(3n) {
//   width: 330px;
// }
.city_info{
    height: 20px;
    line-height: 20px;
    width: 100%;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #aaaaaa;
  }
  .zj_ts_bs{
    // margin-bottom: 10px;
    // margin: 10px auto 6px;
    text-align: center;
    width: 980px;
    // min-height: 40px;
    // line-height: 40px;
    font-size: 16px;
    color: #aaaaaa;
  }
  .detail{
      display: inline-block;
      width:44px;
      color:#ee2e2e;
      cursor:pointer;
    }
.zj_ts_js{
  margin: 0 auto;
  text-align: left;
  padding-top:5px;
  color: #aaaaaa;
  font-size: 16px;
  // width: 980px;
  color: #eeeeee;
}
.u_menu {
  width: $top-left-box-width;
  // margin-top: 14px;
  height: 40px;
  text-align: center;
  margin-right: $top-left-box-margin;
  flex-shrink: 0;
  .u_guanzhu {
    display: inline-block;
    width: 80px;
    height: 36px;
    margin: 2px 5px 0 5px;
    background-color: #ee2e2f;
    border-radius: 2px;
    border: solid 1px #ee2e2f;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ffffff;
    line-height: 35px;
    cursor: pointer;
    border-radius: 8px;
  }
  .u_sixin {
    display: inline-block;
    width: 80px;
    height: 36px;
    margin: 2px 5px 0 5px;
    border-radius: 2px;
    border: solid 1px #8f8f8f;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #ffffff;
    line-height: 35px;
    opacity: 0.59;
    cursor: pointer;
    border-radius: 8px;
  }
}
.bottom_box {
  width: 1000px;
  margin: -60px auto 0;
  padding: 0 30px 30px 30px;
  border-radius: 20px;
  background-color: #fff;
  /** **/
  position: relative;
  display: inline-block;
}
.bot_top {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: #fff;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ededed;
  .t_thread {
    float: left;
    margin-top: 12px;
    margin-left: 25px;
    padding: 0 5px;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    line-height: 45px;
    color: #333333;
    cursor: pointer;
    span {
      font-size: 16px;
      color: #333333;
    }
  }
  .t_archive {
    float: left;
    margin-top: 12px;
    margin-left: 25px;
    padding: 0 5px;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    line-height: 45px;
    color: #222222;
    cursor: pointer;
  }
  .t_thread_s {
    float: left;
    margin-top: 12px;
    margin-left: 25px;
    padding: 0 5px;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    line-height: 45px;
    color: #333333;
    border-bottom: 3px solid #ee2e2e;
    cursor: pointer;
    span {
      font-size: 16px;
      color: #333333;
    }
  }
  .t_archive_s {
    float: left;
    margin-top: 12px;
    margin-left: 25px;
    padding: 0 5px;
    font-family: PingFangSC-Semibold;
    font-size: 18px;
    line-height: 45px;
    color: #222222;
    cursor: pointer;
    border-bottom: 3px solid #ee2e2e;
  }
  .bot_line {
    background-color: #dddddd;
    width: 1px;
    height: 20px;
    float: left;
    margin-left: 25px;
    margin-top: 25px;
  }
  .bot_right {
    float: right;
    margin-top: 12px;
    padding: 0 5px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    line-height: 45px;
    color: #222222;
    .bot_right_g {
      color: #cfa972;
    }
  }
}
.post_msg {
  /deep/.el-form-item__label::before {
    // content: "*";
    // color: #ee2e2e;
    margin-right: 4px;
  }
  /deep/.el-dialog__header {
    text-align: left;
    font-weight: bold;
    font-size: 18px !important;
  }
  /deep/.dialog-footer {
    margin-top: -20px;
    // text-align: center;
  }
  .line {
    text-align: center;
  }
}
.upload_dialog_a .ant-modal {
  width: 750px !important;
}
.upload_flex {
  display: flex;
}
img {
  max-width: none;
}
.up_foot_box {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e8e8e8;
}
.up_foot_button {
  margin-right: 20px;
  background: #ff4c19;
  border: 1px solid #ff4c19;
}
.up_foot_button:hover,
.up_foot_button:focus {
  margin-right: 20px;
  background: #f06640;
  border: 1px solid #f06640;
}
/deep/.ant-modal {
  width: 700px !important;
}

.head {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.change_ava {
  background: rgba(0, 0, 0, 0.5);
  display: none;
  width: 128px;
  height: 128px;
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  border-radius: 50%;
  line-height: 128px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}
.head:hover .change_ava {
  display: block;
  cursor: pointer;
}
/deep/.vue-image-crop-upload .vicp-wrap{
  width: 500px;
}
/deep/.vue-image-crop-upload .vicp-wrap .vicp-operate a{
  color: #ee2e2e;
}
/deep/.vicp-preview .vicp-preview-item span {
  left: 0px;
}
/deep/.vicp-crop-right {
  margin-top: 20px;
  margin-right: 20px;
}
// 头部改版样式
.top_content{
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px 20px;
}
.ts_li{
  flex:1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.u_d_gray {
  width: 100%;
  // height: 40px;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  // color: #aaaaaa;
  color: #eeeeee;
  // line-height: 40px;
  margin: 5px 0;
}
.zj_ts_bs{
  font-size: 16px;
  text-align: left;
  span{
    font-size: 14px;
    color: #fff;
    background: #971818;
    padding: 0 5px;
    border-radius: 5px;
  }
}
.u_d_white {
  width: 100%;
  height: 40px;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #ffffff;
  line-height: 40px;
}
.u_d_white1 {
  position: relative;
  display: flex;
  align-items: center;
  // justify-content: center;
  width: 100%;
  height: 40px;
  font-family: PingFangSC-Regular;
  font-size: 32px;
  color: #ffffff;
  line-height: 40px;
  margin: 5px 0;
  .characteristic{
    font-size: 12px;
    color: #fff;
    background: #971818;
    padding: 1px 5px;
    border-radius: 5px;
    margin-left: 8px;
  }
  .ts_show{
    position: relative;
    img{
      display: inline-block;
      height: 20px;
      margin: 0 0 0 8px;
    }
  }
  .user_tag{
    margin: 0 0 0 8px;
    border-radius: 20px;
    padding: 0 10px;
    font-size: 12px;
    background: #CEA971;
    line-height: 20px;
  }
}
.top_content_top{
  display: flex;
  align-items: center;
}
.top_content_bottom{
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.u_address{
  display: flex;
  align-items: center;
  img{
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
}
</style>
