import request from '@/utils/request'
import bbsrequest from '@/utils/bbsrequest'

/**
 * 修改私密和公开状态
 * name值：tags技能标签|intro个人介绍|educations教育经历|carrers工作经历
 *         projects项目经验|social_work主要论文|achievement个人成就
 *         socialduties社会职务|certificate职业资格证书
 */
export function setPrivacy(params) {
  return request({
    url: 'personal/Myinfo/setPrivacy',
    method: 'get',
    params: params
  })
}

// 发私信
export function sendMessage(params) {
  return bbsrequest({
    url: '/user/user/sendMessage',
    method: 'get',
    params
  })
}

// 档案：删除标签
export function deleteAllTags(params) {
  return request({
    url: 'personal/Myinfo/deleteSkillTags',
    method: 'get',
    params: params
  })
}
// 档案：编辑、添加标签
export function editOrAddTags(params) {
  return request({
    url: 'personal/Myinfo/archiveSkillTags',
    method: 'get',
    params: params
  })
}

/**
 * 工作经历：添加/编辑
 */
export function addOrUpdateArchiveJob(params) {
  return request({
    url: 'personal/Myinfo/addOrUpdateArchiveJob',
    method: 'get',
    params: params
  })
}

/**
 * 工作经历：删除
 *
 */
export function deleteArchiveJob(params) {
  return request({
    url: 'personal/Myinfo/deleteArchiveJob',
    method: 'get',
    params: params
  })
}

/**
 * 档案：添加或编辑或删除 以下五个模块都用此接口; 添加或修改时传相应的参数+值，删除时参数传空字符串
 * 个人介绍：intro
 * 主要论著：social_work
 * 个人成就：achievement
 * 社会职务：socialduties
 * 职业资格证书：certificate
 */
export function archiveItem(params) {
  return request({
    url: 'personal/Myinfo/archiveStrItem',
    method: 'get',
    params: params
  })
}
/**
 * 档案：删除教育经历
 */
export function deleteArchiveEducation(params) {
  return request({
    url: 'personal/Myinfo/deleteArchiveEducation',
    method: 'get',
    params: params
  })
}
/**
 * 档案：添加一个教育经历
 */
export function addArchiveEducation(params) {
  return request({
    url: 'personal/Myinfo/addArchiveEducation',
    method: 'get',
    params: params
  })
}
// 个人主页 -- 是否关注用户
export function isFollowUser(params) {
  return bbsrequest({
    url: 'user/follow/isfollow',
    params: params,
    method: 'get'
  })
}
/**
 * 档案：添加一个教育经历
 */
export function editArchiveEducation(params) {
  return request({
    url: 'personal/Myinfo/updateArchiveEducation',
    method: 'get',
    params: params
  })
}

/**
 * 项目经历： 添加/编辑
 */
export function addOrUpdateArchiveProject(params) {
  return request({
    url: 'personal/Myinfo/addOrUpdateArchiveProject',
    method: 'get',
    params: params
  })
}

/**
 * 项目经历： 删除
 */
export function deleteArchiveProject(params) {
  return request({
    url: 'personal/Myinfo/deleteArchiveProject',
    method: 'get',
    params: params
  })
}
